<template>
    <div class="categories">
        <div class="menu-categories">
            <Category v-for="category in menuCategories" :categoryItem="category" :showChild="true" />
        </div>
        <div class="menu-categories-extension">
            <Category v-for="category in childCategories" :categoryItem="category" :showChild="false" :key="category.id" />
        </div>
    </div>
</template>
  
<script>
import Category from '@/components/categories/Category.vue'
import Network from '@/scripts/networks.js'

export default {
    props: ['categories'],
    components: {
        Category
    },
    data() {
        return {
            menuCategories: this.categories,
            childCategories: {}
        }
    },
    methods: {
        async loadCategoriesById(id) {
            this.childCategories = {}
            const jsonResponse = await Network.get(process.env.VUE_APP_API_BASE_URL + 'categories?id=' + id)
            this.childCategories = jsonResponse["categories"]
        },
        onCategoryClciked(id, title) {
            this.$router.push({
                path: '/search',
                query: { categoryId: id, categoryTitle: title }
            })
        },
        onCategoryMouseEvent(id, hover, isFromParent) {
            var category = document.querySelector(".menu-categories-extension")
            if (hover) {
                category.style.display = "block"
                if (isFromParent) {
                    this.loadCategoriesById(id)
                }
            } else {
                var categories = document.querySelector(".categories")
                if (!categories.matches(':hover')) {
                    category.style.display = "none"
                }
            }
        }
    },
    watch: {
        categories: {
            handler(newVal, _) {
                this.menuCategories = newVal
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>

<style>
.categories {
    position: relative;
}

.menu-categories {
    border-radius: 0px 0px 6px 6px;
    border-right: 1px solid var(--secondary-grey);
    border-bottom: 1px solid var(--secondary-grey);
    border-left: 1px solid var(--secondary-grey);
}

.menu-categories-extension {
    display: none;
    background: var(--white);
    border-radius: 0px 0px 6px 6px;
    border-right: 1px solid var(--secondary-grey);
    border-bottom: 1px solid var(--secondary-grey);
    position: absolute;
    width: 100%;
    left: 100%;
    top: 0;
    z-index: 999;
}
</style>