<template>
    <div class="product-item" @click="onOfferClicked()" @mouseenter="onOfferMouseEnter()" @mouseleave="onOfferMouseLeave()">
        <div class="product-item-main">
            <div class="product-item-top">
                <div class="product-labels">
                    <div v-show="shouldShowPriceLabel" class="product-label">-{{ discount }}%</div>
                    <div v-show="shouldShowEnergeticClassLabel" class="product-label" v-bind:class="{
                        'product-label-class-A': energeticClass === 'A',
                        'product-label-class-B': energeticClass === 'B',
                        'product-label-class-C': energeticClass === 'C',
                        'product-label-class-D': energeticClass === 'D',
                        'product-label-class-E': energeticClass === 'E',
                        'product-label-class-F': energeticClass === 'F',
                        'product-label-class-G': energeticClass === 'G',
                    }">
                        {{ energeticClass }}
                    </div>
                </div>
                <div class="product-item-photo-container">
                    <img class="product-item-photo" :src="imageUrl">
                </div>
            </div>
            <div class="product-item-content">
                <h3 class="product-item-title">{{ title }}</h3>
                <div class="product-item-price">{{ price }} &euro;*</div>
                <div class="product-item-description">Zzgl. Versand: {{ shippingCosts }} <br />{{ unitPrice }}</div>
                <div class="product-item-vendor-container">
                    <img class="product-item-vendor-logo" :src="shopLogo" />
                </div>
                <button class="product-item-button">Angebot ansehen</button>
            </div>
        </div>
        <div class="product-item-details" v-show="itemFocused && shouldShowDetails == true">
            <div class="produc-item-details-content">
                <div class="product-item-details-old-new-price">
                    Statt <s>{{ oldPrice }} &euro;</s>
                    <br />
                    <span class="product-item-details-old-new-price-highlight">jetzt {{ price }} &euro;</span>
                </div>
                <div class="product-item-details-old-saving-percent">
                    <span class="product-item-details-old-saving-percent-highlight">{{ discount }}%</span><br />günstiger
                </div>
                <div class="product-item-details-old-saving-value">Sie sparen<br /><span class="saving-value-highlight">{{
                    saving }} &euro;</span></div>
                <button class="product-item-details-button product-item-button">Jetzt Sparen</button>
            </div>
        </div>
    </div>
</template>

<script>
import Cookie from '@/scripts/cookie';
import Network from '@/scripts/networks.js'
import Session from '@/scripts/session.js'

export default {
    props: ['offerItem'],
    data() {
        return {
            shouldShowDetails: false,
            shouldShowPriceLabel: false,
            shouldShowEnergeticClassLabel: false,
            itemFocused: false,

            id: this.offerItem["id"],
            clickoutUrl: this.offerItem["clickoutUrl"],
            cpc: this.offerItem["shopCPC"],
            shopId: this.offerItem["shopId"],
            categoryId: this.offerItem["categoryId"],

            imageUrl: this.offerItem["offerImageUrl"],
            title: this.offerItem["name"],
            price: this.offerItem["price"],
            shippingCosts: this.offerItem["shippingCosts"] + " €",
            oldPrice: this.offerItem["oldPrice"],
            unitPrice: this.offerItem["unitPrice"],
            discount: this.offerItem["discount"],
            saving: this.offerItem["saving"],
            shopLogo: this.offerItem["shopImageUrl"],
            energeticClass: this.offerItem["energeticClass"]
        }
    },
    methods: {
        onOfferClicked() {
            this.trackClick()
        },
        onOfferMouseEnter() {
            this.itemFocused = true
        },
        onOfferMouseLeave() {
            this.itemFocused = false
        },
        checkForPriceAndDiscount() {
            if (this.oldPrice != null && this.discount > 3) {
                this.shouldShowPriceLabel = true
                this.shouldShowDetails = true
            }
        },
        checkForEnergeticClass() {
            if (this.energeticClass != null) {
                this.shouldShowEnergeticClassLabel = true
            }
        },
        async trackClick() {
            const body = {
                offerId: this.id.toString(),
                shopId: this.shopId,
                cpc: this.cpc.toString(),
                clickOutUrl: this.clickoutUrl,
                categoryId: this.categoryId,
                sessionId: Session.getSession(),
                trafficSource: Cookie.getCookie("adword")
            }
            let response = await Network.post(process.env.VUE_APP_API_BASE_URL + 'tracking/offer/click', body)
            let url = response["clickOutUrl"]
            if (url != null) {
                window.open(url)
            }
        },
    },
    beforeMount() {
        this.checkForPriceAndDiscount()
        this.checkForEnergeticClass()
    },
};
</script>

<style>
/* Product item */

.product-item {
    height: 455px;
    max-height: 455px;
    border-radius: 6px;
    border: 1px solid var(--secondary-grey);
    position: relative;
}

.product-item:hover {
    cursor: pointer;
}

.product-item-main {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
}

/* Product Item Photo */

.product-item-top {
    height: 250px;
    position: relative;
}

.product-item-photo-container {
    width: 100%;
    height: 250px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-item-photo {
    height: 80%;
    width: 80%;
    object-fit: contain;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}

.product-item:hover .product-item-photo {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.product-labels {
    position: absolute;
    min-width: 50px;
    top: 0.5em;
    left: 0.5em;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.product-label {
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 3px;
    color: #FFF;
    font-weight: var(--fw-500);
    font-size: var(--fs-300);
    font-family: var(--ff-pirmary);
    text-align: center;
    line-height: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.product-label-class-A {
    background-color: #00a652;
}

.product-label-class-B {
    background-color: #51b747;
}

.product-label-class-C {
    background-color: #bdd630;
}

.product-label-class-D {
    background-color: #e7dc0a;
}

.product-label-class-E {
    background-color: #fdb813;
}

.product-label-class-F {
    background-color: #f37020;
}

.product-label-class-G {
    background-color: #ed1b24;
}

/* Product Item Content */

.product-item-content {
    width: auto;
    height: 205px;
    padding-left: 1em;
    padding-right: 1em;
    display: grid;
}

/* Product Item Title */

.product-item-title {
    height: 41px;
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    align-self: flex-start;
}

/* Product Item Price */

.product-item-price {
    height: 30px;
    width: 100%;
    color: var(--primary-color);
    font-size: var(--fs-600);
    font-weight: var(--fw-600);
    text-align: center;
    align-self: flex-start;
}

/* Product Item Description */

.product-item-description {
    height: 30px;
    width: 100%;
    font-size: var(--fs-200);
    font-weight: var(--fw-400);
    text-align: center;
}

/* Product Item Vendor */

.product-item-vendor-container {
    height: 35px;
}

.product-item-vendor-logo {
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
    align-self: flex-end;
}

/* Product Item Button */

.product-item-button {
    height: 35px;
    width: 100%;
    border-radius: 6px;
    border: 0px;
    background-color: rgba(var(--primary-color-rgba), 0.4);
    transition: background-color 0.4s;
    color: var(--white);
    font-weight: var(--fw-700);
    font-size: 20px;
    font-family: var(--ff-primary);
    font-variant: small-caps;
    letter-spacing: .02rem;
    align-self: flex-end;
    margin-bottom: 0.5em;
}

.product-item:hover .product-item-button {
    background-color: var(--primary-color);
}

/* Produc Item Details */

.product-item-details {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.produc-item-details-content {
    height: 97%;
    width: 95%;
    background-color: rgba(var(--primary-color-rgba), 0.1);
    display: flex;
    gap: 1.5em;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

/* Product details button */

.product-item-details-button {
    width: 80%;
    align-self: center;
}

/* Product details saving value */

.product-item-details-old-saving-value {
    font-weight: var(--fw-500);
    font-size: var(--fs-900);
    text-align: center;
    color: var(--primary-black);
}

.saving-value-highlight {
    color: var(--primary-color);
    font-weight: var(--fw-600);
    font-size: var(--fs-1000);
}

/* Product details saving percent */

.product-item-details-old-saving-percent {
    text-align: center;
    font-weight: var(--fw-600);
    font-size: var(--fs-1100);
    color: var(--primary-black);
}

.product-item-details-old-saving-percent-highlight {
    font-weight: var(--fw-600);
    font-size: var(--fs-2000);
    color: var(--primary-color);
}

/* Product details saving - old & new */

.product-item-details-old-new-price {
    font-weight: var(--fw-600);
    font-size: var(--fs-600);
    color: var(--primary-black);
    text-align: center;
}

.product-item-details-old-new-price-highlight {
    color: var(--primary-color);
}
</style>