<template>
    <div class="categories-mobile-container">
        <div class="categories-mobile-header">
            <img class="categories-mobile-header-arrow" src="../../assets/right-arrow.svg"
                v-show="categoriesStackId.length > 0" @click="onBackClicked()" />
            <div class="categories-mobile-header-title">Alle Kategorien</div>
            <img class="categories-mobile-header-close" src="../../assets/close-icon.svg" @click="onCloseClicked()" />
        </div>
        <CategoryMobile v-for="category in displayedCategories" :categoryItem="category" :showChild="true"
            :key="category.id" />
    </div>
</template>

<script>
import CategoryMobile from '@/components/categories-mobile/CategoryMobile.vue'
import Network from '@/scripts/networks.js'

export default {
    props: ['categories'],
    components: {
        CategoryMobile
    },
    data() {
        return {
            menuCategories: this.categories,
            displayedCategories: {},
            categoriesStackId: []
        }
    },
    methods: {
        async loadCategoriesById(id, addToStack = true) {
            const jsonResponse = await Network.get(process.env.VUE_APP_API_BASE_URL + 'categories?id=' + id)
            if (jsonResponse != null) {
                this.displayedCategories = jsonResponse["categories"]
                if (addToStack) {
                    this.categoriesStackId.push(id)
                }
            }
        },
        onCategoryClciked(id, title) {
            this.$router.push({
                path: '/search',
                query: { categoryId: id, categoryTitle: title }
            })
            this.$parent.onCategorySelected()
        },
        onCategoryArrowClicked(id) {
            this.loadCategoriesById(id)
        },
        onCloseClicked() {
            this.$parent.onCategoriesCloseClicked()
        },
        onBackClicked() {
            if (this.categoriesStackId.length > 0) {
                this.categoriesStackId.pop()
            }
            if (this.categoriesStackId.length == 0) {
                this.displayedCategories = this.menuCategories
            } else {
                this.loadCategoriesById(this.categoriesStackId[this.categoriesStackId.length - 1], false)
            }
        }
    },
    watch: {
        categories: {
            handler(newVal, _) {
                this.menuCategories = newVal
                this.displayedCategories = this.menuCategories
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>

<style>
.categories-mobile-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.categories-mobile-header {
    min-height: 50px;
    line-height: 50px;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    gap: 1em;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--secondary-grey);
}

.categories-mobile-header-title {
    font-weight: var(--fw-600);
    font-size: var(--fs-500);
    text-transform: capitalize;
    color: var(--primary-black)
}

.categories-mobile-header-arrow {
    width: 15px;
    height: 25px;
    transform: rotate(180deg);
}

.categories-mobile-header-close {
    width: 23px;
    margin-left: auto;
}
</style>