<template>
    <div class="header">
        <div class="heade-container">
            <div class="logo">
                <img class="logo-image" src="../../assets/preistip_logo.svg" @click.prevent="onLogoClicked" />
            </div>
            <form class="search-bar">
                <input class="search-input" @keypress.enter.prevent="onSearchButtonClicked" type="text"
                    v-model="searchQuery" placeholder="Wonach suchen Sie?" />
                <button class="search-button" type="button" @click="onSearchButtonClicked">
                    <div class="search-icon"></div>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            searchQuery: ''
        }
    },
    methods: {
        onSearchButtonClicked() {
            if (this.searchQuery.length > 0) {
                this.$router.push({
                    path: '/search',
                    query: { query: this.searchQuery }
                })
            }
        },
        onLogoClicked() {
            this.$router.push({
                path: '/'
            })
        }
    },
    mounted() {
        if (this.searchQuery !== null && this.searchQuery.trim() !== '')
        {
            document.title = `"${this.searchQuery}" - der transparente Preisvergleich mit Millionen Angeboten für Telefone, Handys, Unterhaltungselektronik und vieles mehr.`
        }
    },
    watch: {
        "$route.query.categoryTitle": {
            handler: function () {
                let title = this.$route.query.categoryTitle;
                if (title !== null && title !== undefined) {
                    this.searchQuery = title;
                }
            },
            deep: true,
            immediate: true,
        },
        "$route.query.query": {
            handler: function () {
                let query = this.$route.query.query;
                if (query !== null && query !== undefined) {
                    this.searchQuery = query;
                }
            },
            deep: true,
            immediate: true,
        }
    },
}
</script>

<style>
.header {
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heade-container {
    max-width: var(--container-width);
    height: 100%;
    padding-left: 1em;
    padding-right: 1em;
    display: grid;
    gap: 1em;
    grid-template-columns: 3fr 9fr;
    flex: 1;
}

.logo {
    min-width: 150px;
    height: 50px;
}

.logo-image {
    height: 55px;
}

.logo-image:hover {
    cursor: pointer;
    background-repeat: no-repeat;
}

.search-bar {
    height: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 10.2fr 1.8fr;
}

.search-input[type=text] {
    background-color: var(--primary-grey);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 0px;
    padding-left: 1em;
    color: #00000080;
    font-weight: var(--fw-400);
    font-size: var(--fs-400);
    font-family: var(--ff-pirmary);
}

.search-button {
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-button:hover {
    cursor: pointer;
}

.search-icon {
    height: 22px;
    width: 22px;
    background: url(../../assets/search-icon.png) no-repeat;
}

/* Width >= 1920px */
@media (width >=120em) {}

/* Width >= 1536px and width < 1920px */
@media (width >=96em) and (width < 120em) {}

/* Width >= 1280px and width < 1536px */
@media (width >=80em) and (width < 96em) {}

/* Width >= 1024px and width < 1280px */
@media (width >=64em) and (width < 80em) {
    .logo-image {
        margin: 0 auto;
    }
}

/* Width >= 896px and width < 1024px */
@media (width >=56em) and (width < 64em) {
    .logo-image {
        margin: 0 auto;
    }
}

/* Width >= 768px and width < 896px */
@media (width >=48em) and (width < 56em) {
    .logo-image {
        margin: 0 auto;
    }
}

/* Width >= 568px and width < 768px */
@media (width >=35.5em) and (width < 48em) {
    .logo-image {
        margin: 0 auto;
    }
}

/* Width < 568px */
@media (width < 35.5em) {
    .heade-container {
        padding-top: 1em;
        padding-bottom: 1em;
        grid-template-columns: 1fr;
        justify-content: center;
    }

    .logo {
        max-width: 100%;
    }

    .logo-image {
        margin: 0 auto;
    }
}
</style>