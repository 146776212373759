<template>
    <h1>Allgemeine Geschäftsbedingungen und Nutzungsbedingungen (AGB)</h1>
    <h2>Stand: 22.03.2023</h2>

    <br/>

    <h3>Geltungsbereich</h3>
    <p>Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) und Nutzungsbedingungen (NutzB) gelten für die Nutzung der Webseite www.preistip.de (im Folgenden "Webseite") und deren Services durch den Nutzer.</p>

    <h3>Vertragspartner</h3>
    <p>Vertragspartner des Nutzers im Hinblick auf die Nutzung der Webseite und deren Services ist die im Impressum genannte 3 Screen Media GmbH (im Folgenden "3SM").</p>

    <h3>Allgemeines</h3>
    <p>3SM behält sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
    <p>3SM behält sich das Recht vor, die NutzB jederzeit zu ändern. Ein ausdrücklicher Hinweis auf die Änderung der NutzB erfolgt nicht. Die NutzB sind selbstständig periodisch auf Veränderungen zu überprüfen.</p>
    <p>Der Nutzer ist verpflichtet, die Webseite und deren Services ausschließlich zu rechtmäßigen Zwecken und in Übereinstimmung mit diesen AGB und NutzB zu nutzen.</p>

    <h3>Urheberrecht</h3>
    <p>Alle Inhalte der Webseite, insbesondere Texte, Bilder, Grafiken, Design und Layout, unterliegen dem Schutz des Urheberrechts und/oder anderer Schutzrechte.</p>
    <p>Die Vervielfältigung, Verbreitung, öffentliche Zugänglichmachung, Bearbeitung, Übertragung oder sonstige Nutzung der Inhalte, insbesondere durch Download, Speicherung oder Erstellung von Kopien, ist nur mit der vorherigen schriftlichen Zustimmung von 3SM gestattet.</p>
    <p>Die unerlaubte Nutzung der Inhalte kann zu zivil- und strafrechtlichen Sanktionen führen.</p>

    <h3>Datenschutz</h3>
    <p>3SM verpflichtet sich, die gesetzlichen Bestimmungen zum Datenschutz zu beachten. Nähere Informationen zum Datenschutz finden Sie in unserer Datenschutzerklärung </p>
    <p>Der Nutzer willigt ein, dass 3SM seine personenbezogenen Daten im Rahmen der Nutzung der Webseite und deren Services gemäß der Datenschutzerklärung erhebt, verarbeitet und nutzt.</p>

    <h3>Jugendschutz</h3>
    <p>3SM achtet auf die Einhaltung der jugendschutzrechtlichen Vorschriften bei den über das Online-Angebot beworbenen Produkten und Angeboten.</p>
    <p>Der Nutzer ist verpflichtet, sich vor der Bestellung oder dem Erwerb von Produkten oder Dienstleistungen über deren Altersfreigabe zu informieren.</p>
    <p>3SM übernimmt keine Verantwortung für die Einhaltung der Jugendschutzvorschriften durch den Nutzer.</p>

    <h2>Gewährleistung und Haftung</h2>

    <h3>Keine Gewähr für die Inhalte</h3>
    <p>3SM übernimmt <strong>keine Gewähr</strong> für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der auf der Webseite bereitgestellten Informationen.</p>

    <h3>Dies gilt insbesondere für:</h3>
    <ul>
        <li>Informationen von Dritten, auf die z.B. über Links verwiesen wird.</li>
        <li>Die Art, den Umfang und die Qualität der von Verkäufern/Shops angebotenen Produkte, Dienstleistungen oder Informationen.</li>
        <li>Die Möglichkeit, die auf den Webseiten dargestellten Produkte und Dienstleistungen zu den angegebenen Bedingungen bei den betreffenden Verkäufern tatsächlich zu erwerben.</li>
        <li>Die graphische, inhaltliche oder technische Gestaltung der Webseiten der Verkäufer/Shops und der darauf angebotenen oder nicht angebotenen Produkte, Dienstleistungen, Informationen und sonstigen Leistungen.</li>
    </ul>

    <br/>
    <h3>Produktinformationen</h3>
    <p>3SM stellt auf seinem Online-Angebot www.preistip.de (im Folgenden "Webseite") Endverbrauchern (im Folgenden "Nutzer") kostenlos Produktinformationen von Drittfirmen (im Folgenden "Händler") zur Verfügung. Diese Informationen dienen lediglich der Orientierung und stellen keine rechtsverbindlichen Angebote dar.</p>

    <h3>Vertragsschluss</h3>
    <p>Mit der Nutzung der Webseite kommt kein Vertrag zwischen dem Nutzer und 3SM zustande. Verträge über den Erwerb der auf der Webseite dargestellten Produkte oder Dienstleistungen werden ausschließlich zwischen dem Nutzer und dem jeweiligen Händler geschlossen.</p>

    <h3>Pflichten des Nutzers</h3>
    <p>Der Nutzer ist verpflichtet, sich vor einem Vertragsschluss mit dem Händler über alle für den Vertragsschluss maßgeblichen Informationen zu informieren, insbesondere über den Preis, die Lieferbedingungen und die Widerrufsbelehrung.</p>

    <h3>Urheberrecht</h3>
    <p>Die auf der Webseite dargestellten Produktinformationen, Firmennamen, Logos und/oder Produktbezeichnungen sind ggf. geschützt oder Warenzeichen der jeweiligen Händler. Die Produktinformationen dürfen vom Nutzer ausschließlich für private Zwecke verwendet werden. Eine gewerbliche Nutzung ist ausgeschlossen.</p>

    <h3>Preise</h3>
    <p>Die auf der Webseite angegebenen Preise verstehen sich inklusive der gesetzlichen Mehrwertsteuer. Die Versandkosten können je nach Händler variieren. Es wird ausdrücklich darauf hingewiesen, dass der angegebene Preis seit der letzten Aktualisierung gestiegen sein kann. Der maßgebliche Preis ist derjenige, welcher zum Zeitpunkt des Kaufs auf der Webseite des Händlers ausgewiesen wird.</p>

    <h3>Haftungsausschluss</h3>
    <p>3SM übernimmt keine Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der auf der Webseite bereitgestellten Produktinformationen. 3SM haftet insbesondere nicht für Schäden, die durch die Nutzung der Webseite entstehen, es sei denn, diese sind von 3SM vorsätzlich oder grob fahrlässig verursacht worden oder es handelt sich um die Verletzung von Leben, Körper oder Gesundheit.</p>

    <h3>Änderungen der Nutzungsbedingungen</h3>
    <p>3SM behält sich das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Die Änderungen werden auf der Webseite veröffentlicht.</p>

    <h3>Keine Gewähr für die technische Verfügbarkeit</h3>
    <p>3SM übernimmt <strong>keine Gewähr</strong> dafür, dass die Webseite www.preistip.de ununterbrochen nutzbar und/oder erreichbar ist.</p>

    <h3>Dies gilt insbesondere für:</h3>
    <ul>
        <li>Technisch bedingte Übertragungsverzögerungen oder Ausfälle.</li>
        <li>Die Funktionstauglichkeit und Kompatibilität von Systemen, Programmen etc., die nicht unmittelbar zu den preistip.de-Plattformen gehören.</li>
    </ul>

    <h3>Keine Haftung</h3>
    <p>Die Nutzung der Webseite www.preistip.de erfolgt <strong>auf eigene Gefahr</strong>. 3SM haftet <strong>nicht</strong> für Schäden, die durch die Nutzung der Webseite entstehen, es sei denn, diese sind von 3SM vorsätzlich oder grob fahrlässig verursacht worden oder es handelt sich um die Verletzung von Leben, Körper oder Gesundheit.</p>

    <h3>Dies gilt insbesondere für Schäden, die durch:</h3>
    <ul>
        <li>Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung.</li>
        <li>Störungen der technischen Anlagen.</li>
        <li>Verlust und Löschung von Daten.</li>
        <li>Viren.</li>
    </ul>

    <br/>
    <h3>Haftungsausschluss für Links</h3>
    <p>3SM übernimmt <strong>keine Haftung</strong> für die Inhalte, die auf Webseiten Dritter bereitgestellt werden, auf die über Links von www.preistip.de verwiesen wird.</p>

    <h3>Unentgeltlichkeit der Dienste</h3>
    <p>Die von 3SM unter <a target="_blank" href="https://optout.aboutads.info/?c=2&lang=EN">www.preistip.de</a> zur Verfügung gestellten Dienste sind <strong>unentgeltlich</strong>.</p>

    <h3>Änderungen der AGB</h3>
    <p>3SM behält sich das Recht vor, diese AGB jederzeit zu ändern. Die Änderungen werden auf der Webseite www.preistip.de veröffentlicht.</p>

    <h3>Schlussbestimmungen</h3>
    <h4>Anwendbares Recht</h4>
    <p>Auf diese AGB und Nutzungsbedingungen findet ausschließlich deutsches Recht Anwendung unter Ausschluss des UN-Kaufrechts.</p>

    <h4>Gerichtsstand</h4>
    <p>Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesen AGB und Nutzungsbedingungen ist Stuttgart.</p>

    <h4>Salvatorische Klausel</h4>
    <p>Sollten einzelne Bestimmungen dieser AGB und Nutzungsbedingungen unwirksam sein oder werden, so bleiben die übrigen Bestimmungen dieser AGB und Nutzungsbedingungen hiervon unberührt.</p>

    <h2>Kontaktdaten</h2>
    <img class="full" src="../../assets/Impressum1.png">

</template>
  
<script>
export default {
    methods: {
        onDatenschutzClicked() {
            this.$router.push({
                path: "/datenschutz",
            });
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
};
</script>

<style>
.full {
    max-width: 600px;
}
</style>