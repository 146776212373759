import Cookie from '@/scripts/cookie'

var params = {
    checkAndSaveInitParams() {
        const adword = this.getParam(HTTPMethodsType.AdWord)
        if (adword !== null && adword !== "") {
            Cookie.setCookieWithExpiration(HTTPMethodsType.AdWord, adword, 1)
        }
    },
    getParam(name) {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get(name)
    }
}

const HTTPMethodsType = {
    AdWord: "adword"
}

export default params