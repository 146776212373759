<template>
    <h1>Datenschutzerklärung</h1>
    <h2>(Stand: Markgröningen, 21.01.2022)</h2>

    <br/>
    <p>Diese Erklärung bietet einen Überblick, wie wir mit Ihren personenbezogenen Daten umgehen, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Informationen, die Sie persönlich identifizieren können.</p>
   
    <h3>Datenverarbeitung und Datenerfassung</h3>
    <p>Verantwortlich für die Datenverarbeitung auf dieser Website ist der im Impressum genannte Websitebetreiber.</p>
    <p>Erfasste Daten:</p>
    <ul>
        <li><strong>Daten, die Sie uns direkt mitteilen: </strong>Dies können Informationen sein, die Sie in ein Kontaktformular eingeben oder uns auf andere Weise zukommen lassen.</li>
        <li><strong>Automatisch erfasste Daten: </strong>Beim Besuch unserer Website erfassen unsere Systeme automatisch technische Daten wie Ihren Browser, Betriebssystem und die Uhrzeit des Seitenaufrufs.</li>
    </ul>
    <p>Zwecke der Datenverwendung:</p>
    <ul>
        <li><strong>Bereitstellung der Website: </strong>Ein Teil Ihrer Daten ist notwendig, um die Website fehlerfrei zu betreiben und Ihnen die gewünschten Funktionen zur Verfügung zu stellen.</li>
        <li><strong>Analyse des Nutzerverhaltens: </strong>Anhand Ihrer Daten können wir Ihr Nutzungsverhalten besser verstehen und unsere Website optimieren.</li>
    </ul>
    <p>Sie haben jederzeit folgende Rechte:</p>
    <ul>
        <li><strong>Auskunft: </strong>Sie können jederzeit kostenfrei Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten Daten verlangen.</li>
        <li><strong>Berichtigung, Sperrung und Löschung: </strong>Sie haben das Recht, die Berichtigung, Sperrung oder Löschung Ihrer Daten zu verlangen.</li>
        <li><strong>Einschränkung der Verarbeitung: </strong>Unter bestimmten Umständen können Sie die Einschränkung der Verarbeitung Ihrer Daten verlangen.</li>
        <li><strong>Widerspruch: </strong>Sie können der Nutzung Ihrer Daten für bestimmte Zwecke widersprechen.</li>
        <li><strong>Datenübertragbarkeit: </strong>Sie haben das Recht, Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und sie an einen anderen Verantwortlichen zu übermitteln.</li>
        <li><strong>Beschwerde: </strong>Bei der zuständigen Aufsichtsbehörde können Sie sich über unsere Datenverarbeitung beschweren.</li>
    </ul>

    <br/>
    <h3>Analyse-Tools und Tools von Drittanbietern</h3>
    <p>Beim Besuch unserer Website kann Ihr Surfverhalten statistisch ausgewertet werden. Dies geschieht mithilfe von Cookies und Analyseprogrammen. Die Auswertung erfolgt in der Regel anonymisiert, d.h. Ihr Surfverhalten kann nicht Ihnen persönlich zugeordnet werden.</p>
    <p>Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Details finden Sie in unserer Datenschutzerklärung.</p>

    <h3>Datenschutz ist uns wichtig!</h3>
    <p>Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und behandeln sie gemäß den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
    <p>Wenn Sie unsere Website nutzen, erheben wir verschiedene personenbezogene Daten. Das sind Informationen, die Sie persönlich identifizieren können, wie zum Beispiel:</p>
    <ul>
        <li>IP-Adresse</li>
        <li>Browser und Betriebssystem</li>
        <li>Datum und Uhrzeit des Seitenaufrufs</li>
    </ul>

    <p>Wir verwenden Ihre Daten für folgende Zwecke:</p>
    <ul>
        <li><strong>Bereitstellung der Website und ihrer Funktionen: </strong>Ihre Daten sind notwendig, um Ihnen die Inhalte und Funktionen unserer Website korrekt und zuverlässig zur Verfügung zu stellen.</li>
        <li><strong>Verbesserung unserer Website: </strong>Anhand Ihrer Daten können wir unsere Website analysieren und optimieren, um Ihnen ein besseres Nutzererlebnis zu bieten.</li>
        <li><strong>Versenden von Informationen und Angeboten: </strong>Mit Ihrer Einwilligung können wir Ihnen per E-Mail oder anderen Kanälen Informationen über unsere Produkte und Dienstleistungen zukommen lassen.</li>
        <li><strong>Verhindern von Missbrauch: </strong>Ihre Daten können dazu beitragen, Missbrauch auf unserer Website zu verhindern und die Sicherheit unserer Systeme zu gewährleisten.</li>
    </ul>

    <p>Sie haben jederzeit das Recht auf:</p>
    <ul>
        <li><strong>Auskunft: </strong>Sie können jederzeit kostenfrei Auskunft über Ihre gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der Datenverarbeitung erhalten.</li>
        <li><strong>Berichtigung: </strong>Sie können die Berichtigung unrichtiger oder unvollständiger Daten verlangen.</li>
        <li><strong>Löschung: </strong>Sie können die Löschung Ihrer Daten verlangen, sofern die Voraussetzungen hierfür erfüllt sind.</li>
        <li><strong>Einschränkung der Verarbeitung: </strong>Sie können die Einschränkung der Verarbeitung Ihrer Daten verlangen.</li>
        <li><strong>Widerspruch: </strong>Sie können der Verarbeitung Ihrer Daten widersprechen.</li>
        <li><strong>Datenübertragbarkeit: </strong>Sie können Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format erhalten und an einen anderen Verantwortlichen übermitteln.</li>
        <li><strong>Widerruf Ihrer Einwilligung: </strong>Sie können Ihre Einwilligung zur Datenverarbeitung jederzeit widerrufen.</li>
        <li><strong>Beschwerde: </strong>Sie haben das Recht, sich bei der zuständigen Aufsichtsbehörde über die Verarbeitung Ihrer Daten durch uns zu beschweren.</li>
    </ul>
    <p>Wir treffen angemessene technische und organisatorische Maßnahmen, um Ihre Daten vor unbefugtem Zugriff, Verlust, Verfälschung oder Zerstörung zu schützen.</p>

    <h3>Änderungen dieser Datenschutzerklärung:</h3>
    <p>Diese Datenschutzerklärung kann von uns jederzeit angepasst werden. Die aktuelle Version finden Sie immer auf unserer Website.</p>

    <h3>Kontakt:</h3>
    <p>Bei Fragen zum Datenschutz auf unserer Website können Sie sich jederzeit an uns wenden.</p>

    <h2>Partnerprogramm- & Affiliate-Links auf preistip.de</h2>
    <p>preistip.de arbeitet mit ausgewählten Partnerprogrammen und Affiliate-Marketing-Partnern zusammen. So können wir Ihnen noch mehr Produkte und Angebote anbieten und Ihnen gleichzeitig die besten Preise vermitteln.</p>
    <p>Wenn Sie über einen unserer Affiliate-Links auf einen Partner-Shop geleitet werden und dort ein Produkt kaufen, erhalten wir vom Shopbetreiber eine Provision. Diese Provision hat <strong>keinen Einfluss auf den Preis, den Sie bezahlen</strong>. Sie ermöglicht es uns aber, preistip.de weiter zu betreiben und Ihnen weiterhin kostenlose Angebote und Services zur Verfügung zu stellen.</p>
    
    <p>Um die Provision zu berechnen, erheben wir folgende Daten:</p>
    <ul>
        <li><strong>Server-Log-Informationen: </strong>Technische Daten Ihres Geräts und Ihrer Internetverbindung</li>
        <li><strong>Referrer-ID: </strong>Die Website, von der Sie zu preistip.de gekommen sind</li>
        <li><strong>Geklicktes Produkt: </strong>Das Produkt, das Sie auf preistip.de angeklickt haben</li>
        <li><strong>Kaufabschluss (optional): </strong>Ob Sie das Produkt im Partner-Shop gekauft haben</li>
    </ul>
    
    <br/>
    <h3>Datenschutz und Datensicherheit</h3>
    <p>Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. preistip.de hat ein <strong>berechtigtes Interesse</strong> an der Erhebung dieser Daten, da sie für die Abrechnung mit unseren Partnern erforderlich sind.</p>
    
    <h3>Speicherung und Löschung Ihrer Daten</h3>
    <p>preistip.de löscht Ihre Daten, sobald die Nachweispflichten für die Rechnungsstellung an unsere Partner erfüllt sind.</p>

    <h2>Beschwerderechte</h2>
    <p>Landesbeauftragte für den Datenschutz und die Informationsfreiheit Baden-Württemberg
        <br/>Königstrasse 10 a
        <br/>70173 Stuttgart
        <br>Tel.: +49 711 6155410
        <br>Fax: +49 711 61554115
        <br>E-Mail: poststelle@lfdi.bwl.de
    </p>

    <h3>Protokolldateien (Server-Logs)</h3>
    <p>Unser Webserver zeichnet automatisch Informationen in sogenannten Log-Dateien auf, die Ihr Browser an uns übermittelt. Diese Daten umfassen:</p>
    <ul>
        <li><strong>Browsertyp und -version</strong></li>
        <li><strong>Verwendetes Betriebssystem</strong></li>
        <li><strong>Referrer-URL </strong>(die Website, von der Sie auf unsere Website gekommen sind)</li>
        <li><strong>Hostname des zugreifenden Computers</strong></li>
        <li><strong>Uhrzeit der Serveranfrage</strong></li>
        <li><strong>IP-Adresse</strong></li>
    </ul>

    <p>Diese Daten werden <strong>nicht mit anderen Datenquellen</strong> zusammengeführt.</p>
    <p>Die Erhebung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch einwandfreien Darstellung und Optimierung seiner Website. Die Server-Log-Dateien sind hierfür erforderlich.</p>
    
    <h3>Zweck der Datenerfassung:</h3>
    <ul>
        <li><strong>Sicherstellung der technischen Verfügbarkeit und Stabilität der Website</strong></li>
        <li><strong>Erkennung und Behebung von technischen Fehlern</strong></li>
        <li><strong>Verbesserung der Leistung und Funktionalität der Website</strong></li>
    </ul>

    <br/>
    <h3>Speicherdauer:</h3>
    <p>Die Server-Log-Dateien werden <strong>für einen begrenzten Zeitraum</strong> gespeichert, der für die oben genannten Zwecke erforderlich ist. Danach werden die Daten gelöscht oder anonymisiert.</p>

    <h3>Änderungen:</h3>
    <p>Diese Datenschutzerklärung wurde zuletzt am 21.01.2022 aktualisiert. Wir behalten uns vor, diese Erklärung jederzeit anzupassen, insbesondere bei Änderungen der gesetzlichen Vorgaben oder der von uns verwendeten Technik. Die aktuelle Version finden Sie immer auf unserer Website.</p>

    <h3>Kontakt per E-Mail, Telefon oder Fax</h3>
    <p>Wenn Sie uns per E-Mail, Telefon oder Fax kontaktieren, speichern und verarbeiten wir Ihre Anfrage <strong>einschließlich aller darin enthaltenen personenbezogenen Daten</strong> (z.B. Name, Inhalt Ihrer Anfrage) <strong>ausschließlich</strong> zur Bearbeitung Ihres Anliegens. Ihre Daten werden ohne Ihre Einwilligung <strong>nicht an Dritte weitergegeben</strong>.</p>

    <h3>Rechtsgrundlage für die Verarbeitung:</h3>
    <ul>
        <li><strong>Vertrag oder vorvertragliche Maßnahmen: </strong>Die Verarbeitung Ihrer Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, wenn Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.</li>
        <li><strong>Ihre Einwilligung: </strong>In allen anderen Fällen erfolgt die Verarbeitung auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO). Wir haben ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen.</li>
    </ul>

    <br/>
    <h3>Speicherdauer:</h3>
    <p>Die von Ihnen übermittelten Daten werden <strong>solange gespeichert</strong>, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach Abschluss der Bearbeitung Ihres Anliegens). <strong>Zwingende gesetzliche Bestimmungen</strong>, insbesondere gesetzliche Aufbewahrungsfristen, bleiben hiervon unberührt.</p>

    <h2>Cookies</h2>
    <h3>Ohne Cookies geht es nicht!</h3>
    <p>Preistip.de setzt sogenannte Cookies ein, um Ihnen die bestmögliche Nutzung unserer Website zu bieten. Cookies sind kleine Textdateien, die auf Ihrem Computer gespeichert werden und uns dabei helfen, unsere Inhalte anzuzeigen, zu finanzieren und zu verbessern.</p>

    <h3>Warum verwenden wir Cookies?</h3>
    <ul>
        <li><strong>Sicherheit: </strong>Cookies ermöglichen es uns, wichtige Funktionen für die Sicherheit unserer Website bereitzustellen.</li>
        <li><strong>Analyse: </strong>Mit Hilfe von Cookies können wir unsere Website analysieren und so verbessern, dass sie für Sie noch benutzerfreundlicher wird.</li>
        <li><strong>Inhalte und Bilder: </strong>Cookies stellen sicher, dass Ihnen Inhalte und Bilder korrekt angezeigt werden.</li>
        <li><strong>Session Management: </strong>Cookies ermöglichen es uns, Ihre Sitzung auf unserer Website zu verwalten.</li>
    </ul>
    <br/>

    <h3>Ihre Einwilligung</h3>
    <p>Mit der Nutzung unserer Website erklären Sie sich mit der Verwendung der oben genannten Cookies einverstanden. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie Ihre Browsereinstellungen so ändern, dass Cookies blockiert werden. Bitte beachten Sie jedoch, dass Sie in diesem Fall einige Funktionen unserer Website möglicherweise nicht mehr nutzen können.</p>

    <h3>Verarbeitung von Daten (Nutzerdaten und Partner-Vertragsdaten)</h3>
    <p>Wir erheben, verarbeiten und nutzen Ihre personenbezogenen Daten nur, soweit dies für die Abwicklung unserer Leistungen erforderlich ist. Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>

    <h3>Nutzungsdaten</h3>
    <p>Personenbezogene Daten über die Nutzung unserer Website erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um Ihnen die Nutzung unserer Dienste zu ermöglichen oder abzurechnen.</p>

    <h2>Analytics und Ads (Werbung)</h2>

    <h3>Google AdSense</h3>
    <p>Diese Website nutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen der Google Inc. ("Google"). Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.</p>

    <h3>Wie funktioniert Google AdSense auf unserer Website?</h3>

    <ul>
        <li><strong>"Nicht personalisierte Anzeigen": </strong>Wir verwenden Google AdSense im Modus "Nicht personalisierte Anzeigen". Das bedeutet, dass nur Cookies eingesetzt werden, die für die folgenden Zwecke notwendig sind: </li>
        <ul>
            <li><strong>Frequency Capping: </strong>Begrenzung der Häufigkeit, mit der Ihnen dieselbe Anzeige angezeigt wird.</li>
            <li><strong>Zusammengefasste Anzeigenberichte: </strong>Erstellung von Berichten über die Leistung der Anzeigen auf unserer Website.</li>
            <li><strong>Bekämpfung von Betrug und Missbrauch: </strong>Verhinderung von betrügerischen Aktivitäten und Missbrauch des AdSense-Systems.</li>
        </ul>
        <li><strong>Keine personalisierte Werbung: </strong>Es werden <strong>keine</strong> Cookies verwendet, um Ihnen personalisierte Werbung auf Basis Ihrer Interessen oder Ihres Surfverhaltens anzuzeigen.</li>
    </ul>

    <br/>
    <h3>Welche Daten werden erhoben und wie werden sie verwendet?</h3>
    <ul>
        <li><strong>Cookies und Web Beacons: </strong>Google AdSense verwendet Cookies und sogenannte Web Beacons (unsichtbare Grafiken), um Informationen über die Nutzung unserer Website zu sammeln. Diese Informationen umfassen: </li>
        <ul>
            <li>Besucherverkehr auf unserer Website</li>
            <li>Art und Anzahl der angezeigten Anzeigen</li>
            <li>Klickverhalten auf Anzeigen</li>
            <li>IP-Adresse</li>
        </ul>
        <li><strong>Speicherung und Weitergabe von Daten: </strong>Die gesammelten Informationen werden an einen Server von Google in den USA übertragen und dort gespeichert. Google kann diese Daten an Vertragspartner weitergeben. Google wird Ihre IP-Adresse jedoch <strong>nicht</strong> mit anderen von Ihnen bei Google gespeicherten Daten zusammenführen.</li>
    </ul>

    <br/>
    <h3>Rechtsgrundlage für die Datenverarbeitung:</h3>
    <p>Die Speicherung von AdSense-Cookies erfolgt auf Grundlage Ihrer <strong>Einwilligung</strong> (Art. 6 Abs. 1 lit. a DSGVO).</p>

    <h3>Widerspruchsrecht und Widerruf der Einwilligung:</h3>
    <p>Sie können der beschriebenen Nutzung von Google AdSense jederzeit widersprechen und die Installation der Cookies auf verschiedene Weise verhindern:</p>

    <ul>
        <li><strong>Browser-Einstellungen: </strong>Stellen Sie Ihren Browser so ein, dass keine Cookies von Drittanbietern akzeptiert werden. Dadurch werden Ihnen auch keine Anzeigen von Drittanbietern mehr angezeigt.</li>
        <li><strong>Deaktivierung personalisierter Werbung: </strong>Deaktivieren Sie die interessenbezogenen Anzeigen bei Google über den Link <a target="_blank" rel="nofollow" href="https://adssettings.google.com/">https://adssettings.google.com/</a>. <strong>Hinweis</strong>: Diese Einstellung wird gelöscht, wenn Sie Ihre Cookies löschen.</li>
        <li><strong>Deaktivierung durch Drittanbieter: </strong>Deaktivieren Sie die interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne "About Ads" sind, über den Link <a target="_blank" rel="nofollow" href="https://optout.aboutads.info/">https://optout.aboutads.info/</a>. <strong>Hinweis</strong>: Diese Einstellung wird gelöscht, wenn Sie Ihre Cookies löschen.</li>
        <li><strong>Dauerhafte Deaktivierung: </strong>Deaktivieren Sie die AdSense-Cookies dauerhaft in Ihrem Browser. Anleitungen für verschiedene Browser finden Sie unter dem Link <a target="_blank" rel="nofollow" href="https://adssettings.google.com/">https://adssettings.google.com/</a>. <strong>Hinweis</strong>: In diesem Fall können Sie einige Funktionen unserer Website möglicherweise nicht mehr nutzen.</li>
    </ul>

    <br/>
    <h3>Weitere Informationen:</h3>
    <ul>
        <li><strong>Google Datenschutzhinweise für Werbung: <a target="_blank" rel="nofollow" href="https://policies.google.com/technologies/ads?hl=de&gl=de">https://policies.google.com/technologies/ads?hl=de&gl=de</a></strong></li>
        <li><strong></strong>Google AdSense Hilfe: <a target="_blank" rel="nofollow" href="https://support.google.com/adsense/topic/13821022?hl=de&ref_topic=10924668&sjid=14125101583158271809-EU">https://support.google.com/adsense/topic/13821022?hl=de&ref_topic=10924668&sjid=14125101583158271809-EU</a></li>
        <li><strong>About Ads: <a target="_blank" rel="nofollow" href="https://optout.aboutads.info/?c=2&lang=EN">https://optout.aboutads.info/?c=2&lang=EN</a></strong></li>
    </ul>

    <br/>
    <h2>Newsletter</h2>

    <h3>Anmeldung zum Newsletter</h3>
    <p>Um unseren Newsletter zu abonnieren, benötigen wir Ihre E-Mail-Adresse. Außerdem benötigen wir Informationen, die uns die Überprüfung ermöglichen, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind.</p>

    <h3>Wir erheben folgende Daten</h3>
    <ul>
        <li>E-Mail-Adresse</li>
        <li>Informationen zur Überprüfung der Inhaberschaft der E-Mail-Adresse</li>
        <li>Freiwillige Zusatzinformationen (optional)</li>
    </ul>
    <p>Wir verwenden Ihre Daten ausschließlich für den Versand des Newsletters und geben diese nicht an Dritte weiter.</p>
    <p>Die Verarbeitung Ihrer Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
    <p>Sie können Ihre Einwilligung jederzeit widerrufen. Dies können Sie beispielsweise über den "Austragen"-Link im Newsletter tun. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
    <p>Ihre Daten werden gespeichert, bis Sie den Newsletter abbestellen. Nach der Abbestellung werden Ihre Daten gelöscht</p>

    <h2>Hosting</h2>

    <p>Diese Website wird auf Amazon Web Services (AWS), einer sicheren und zuverlässigen Cloud-Plattform, gehostet.</p>
    <p>Auf den AWS-Servern werden unter anderem folgende Daten gespeichert:</p>
    <ul>
        <li><strong>IP-Adressen: </strong>Diese Daten werden benötigt, um Ihnen die Inhalte unserer Website korrekt anzeigen zu können.</li>
        <li><strong>Kontaktanfragen: </strong>Wenn Sie uns über unsere Website kontaktieren, speichern wir Ihre Anfrage und die von Ihnen angegebenen Daten, um Ihnen bestmöglich weiterhelfen zu können.</li>
        <li><strong>Meta- und Kommunikationsdaten: </strong>Diese Daten protokollieren Ihre Nutzung unserer Website und helfen uns, unsere Angebote zu verbessern.</li>
        <li><strong>Vertragsdaten: </strong>Wenn Sie mit uns einen Vertrag abschließen, speichern wir Ihre Vertragsdaten auf den AWS-Servern.</li>
        <li><strong>Kontaktdaten: </strong>Wenn Sie uns Ihre Kontaktdaten mitteilen, speichern wir diese, um Sie über Neuigkeiten und Angebote informieren zu können.</li>
        <li><strong>Namen:</strong>Wenn Sie uns Ihren Namen mitteilen, speichern wir diesen, um Sie persönlich ansprechen zu können.</li>
        <li><strong>Webseitenzugriffe: </strong>Diese Daten protokollieren Ihre Nutzung unserer Website und helfen uns, unsere Angebote zu verbessern.</li>
        <li><strong>Sonstige Daten: </strong>Weitere Daten, die über unsere Website generiert werden, können ebenfalls auf den AWS-Servern gespeichert werden.</li>
    </ul>

    <br/>
    <h3>Warum speichern wir Ihre Daten auf AWS?</h3>
    <p>Wir speichern Ihre Daten auf AWS, weil:</p>
    <ul>
        <li><strong>Sicherheit: </strong>AWS bietet höchste Sicherheitsstandards und schützt Ihre Daten vor unbefugtem Zugriff.</li>
        <li><strong>Zuverlässigkeit: </strong>AWS ist eine extrem zuverlässige Plattform und gewährleistet die Verfügbarkeit unserer Website.</li>
        <li><strong>Schnelligkeit: </strong>AWS ermöglicht eine schnelle und effiziente Bereitstellung unserer Website.</li>
        <li><strong>Skalierbarkeit: </strong>AWS ist skalierbar und kann an unsere wachsenden Anforderungen angepasst werden.</li>
    </ul>

    <br/>
    <h3>Rechtsgrundlage für die Datenverarbeitung:</h3>
    <p>Die Verarbeitung Ihrer Daten auf AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung) und Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse liegt in der sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots.</p>
    <p>Amazon Web Services (AWS) ist gemäß EU-US Privacy Shield zertifiziert und gewährleistet ein hohes Maß an Datenschutz. Sie können die Datenschutzbestimmungen von AWS hier: <a target="_blank" rel="nofollow" href="https://aws.amazon.com/de/compliance/data-privacy/">https://aws.amazon.com/de/compliance/data-privacy/</a></p>

    <h3>Vertrag über Auftragsverarbeitung:</h3>
    <p>Um die datenschutzkonforme Verarbeitung Ihrer Daten auf AWS zu gewährleisten, haben wir mit AWS einen Vertrag über Auftragsverarbeitung geschlossen. Dieser Vertrag verpflichtet AWS, Ihre Daten nur im Einklang mit unseren Weisungen und den datenschutzrechtlichen Bestimmungen zu verarbeiten.</p>

    <h2>Verantwortlich für die Datenverarbeitung auf dieser Website ist:</h2>
    <img class="full" src="../../assets/Impressum1.png"></template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0)
    },
};
</script>

<style>.full {
    max-width: 600px;
}
p {
    margin: 1em 0em 1em 1em;
}

ul {
    margin-left: 2em;
}</style>