<template>
    <div class="best-products-container">
        <Offers :offers="offers" />
    </div>
</template>
  
<script>
import Offers from '@/components/offer/Offers.vue'
import Network from '@/scripts/networks.js'

export default {
    components: {
        Offers
    },
    data() {
        return {
            offers: {}
        }
    },
    methods: {
        async loadTopProducts() {
            const jsonResponse = await Network.get(process.env.VUE_APP_API_BASE_URL + 'offers/top?count=20');
            this.offers = jsonResponse["offers"]
        }
    },
    beforeMount() {
        this.loadTopProducts()
    }
}
</script>
  
<style>
/* Best Products Section */

.best-products-container {
    max-width: var(--container-width);
    padding-left: 1em;
    padding-right: 1em;
    display: grid;
    gap: 1em;
    flex: 1;
}
</style>