<template>
    <div class="hero-container"></div>
</template>

<style>
.hero-container {
    background-color: #1484A9;
    margin-top: 1em;
    border-radius: 6px;
    background: url(../../assets/hero-placeholder.png) center;
}
</style>