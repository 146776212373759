<template>
    <div class="category-mobile">
        <div class="category-mobile-title" @click.prevent="onCategoryClciked(id, title)">{{ title }}</div>
        <img v-if="showChild" class="category-mobile-arrow" src="../../assets/right-arrow.svg"
            @click.prevent="onCategoryArrowClicked(id)" />
    </div>
</template>

<script>
export default {
    props: ['categoryItem', 'showChild'],
    data() {
        return {
            id: this.categoryItem["id"],
            title: this.categoryItem["title"]
        }
    },
    methods: {
        onCategoryClciked(id, title) {
            this.$parent.onCategoryClciked(id, title)
        },
        onCategoryArrowClicked(id) {
            this.$parent.onCategoryArrowClicked(id)
        }
    }
}
</script>

<style>
.category-mobile {
    min-height: 50px;
    line-height: 50px;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary-grey);
}

.category-mobile-title {
    font-weight: var(--fw-500);
    font-size: var(--fs-400);
    text-transform: capitalize;
    color: var(--primary-black)
}

.category-mobile:hover .category-mobile-title {
    color: var(--primary-color);
    transition: color 0.4s;
}

.category-mobile-arrow {
    width: 15px;
    height: 25px;
    margin-left: auto;
}
</style>