<template>
    <div class="footer">
        <div class="top-footer">
            <div class="top-footer-container">
                <div class="footer-disclaimer">
                    Mit Preistip Millionen Angebote von tausenden Shops vergleichen - und richtig Geld sparen. Machen Sie
                    Schluss mit undurchsichtigen Preisen für Handys, Unterhaltungselektronik, E-bikes, Computer, Software
                    und Tablets. Onlineshopping kann so einfach und übersichtlich sein. Alle Kosten auf einen Blick -
                    transparent, fair und ganz einfach von zu Hause aus.<br /><br />*Alle Preise sind inklusive der
                    geltenden gesetzlichen Mehrwertsteuer angegeben, ggfls. zzgl. Versandkosten. Wir übernehmen
                    keine Garantie für die Korrektheit der Angaben. Änderungen können sich jederzeit ergeben. Die
                    durchgestrichenen Preise
                    entsprechen den ursprünglichen Verkaufspreisen des Anbieters. Unser Vergleichsportal präsentiert
                    Angebote verschiedener Händler,
                    von denen wir im Falle einer Weiterleitung oder eines Verkaufs eine Provision erhalten.
                </div>
            </div>
        </div>
        <div class="bottom-footer">
            <div class="bottom-footer-container bottom-footer-link">
                <a href="" @click.prevent="onDatenschutzClicked()">Datenschutz</a> |
                <a href="" @click.prevent="onNutzungsbedingungenClicked()">Nutzungsbedingungen</a> |
                <a href="" @click.prevent="onImpressumClicked()">Impressum</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        onDatenschutzClicked() {
            this.$router.push({
                path: '/datenschutz'
            })
        },
        onNutzungsbedingungenClicked() {
            this.$router.push({
                path: '/nutzungsbedingungen'
            })
        },
        onImpressumClicked() {
            this.$router.push({
                path: '/impressum'
            })
        }
    }
}

</script>

<style>
.top-footer-container {
    height: auto;
    max-width: var(--container-width);
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    min-height: 250px;
}

.top-footer {
    min-height: 200px;
    background: var(--primary-black);
    display: flex;
    justify-content: center;
}

.footer-disclaimer {
    color: var(--white);
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    text-align: center;
}

.bottom-footer {
    min-height: 50px;
    background: var(--secondary-black);
    display: flex;
    justify-content: center;
}

.bottom-footer-container {
    height: auto;
    max-width: var(--container-width);
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
    color: var(--white);
    font-size: var(--fs-400);
    font-weight: var(--fw-600);
}

.bottom-footer-container a {
    color: var(--white);
    font-size: var(--fs-400);
    font-weight: var(--fw-600);
}

.bottom-footer-container a:hover {
    color: var(--primary-color);
    transition: color 0.4s;
}</style>