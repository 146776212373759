<template>
	<div class="ads-product-item">
		<div id="ads-product-item-ad-container" class="ads-product-item-ad-container">
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		loadGoogleAdsenseStatic(container, slot) {
			try {
				let adContainer = document.getElementById(container);
				if (!adContainer) {
					console.error(`Error: Container with ID "${container}" not found.`);
					return;
				}

				let ins = document.createElement('ins');
				ins.className = 'adsbygoogle';
				ins.style.display = 'block';
				ins.setAttribute('data-ad-client', 'ca-pub-4833053544773030');
				ins.setAttribute('data-ad-slot', slot);
				ins.setAttribute('data-ad-format', 'auto');
				ins.setAttribute('data-full-width-responsive', 'true');
				adContainer.appendChild(ins);

				if (!window.adsbygoogle) {
					console.error("Error: Google Adsense script is not loaded or 'adsbygoogle' is undefined.");
					return;
				}
				(window.adsbygoogle = window.adsbygoogle || []).push({});
			} catch (error) {
				console.error("Unexpected error while loading Google Adsense ad:", error);
			}
		}
	},
	mounted() {
		this.loadGoogleAdsenseStatic('ads-product-item-ad-container', '2527845664');
	}
}
</script>

<style>
.ads-product-item {
	height: 455px;
	max-height: 455px;
	border-radius: 6px;
	border: 1px solid var(--secondary-grey);
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.ads-product-item-ad-container {
	width: 98%;
	height: 98%;
	border-radius: 6px;
}
</style>