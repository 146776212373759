<template>
    <h3>Impressum</h3>
    <img class="full" src="../../assets/Impressum1.png">
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0)
    },
};
</script>

<style>
.full {
    max-width: 600px;
}
</style>