<template>
    <AppHeader />
    <CategoriesMobile :categories="menuCategories" v-show="mobileCategoriesVisible" />
    <MenuBar />
    <HomeContent :categories="menuCategories" />
    <BottomFooter />
</template>
  
<script>
import AppHeader from "@/components/header/Header.vue";
import MenuBar from "@/components/menu-bar/MenuBar.vue";
import HomeContent from "@/components/home/HomeContent.vue";
import BottomFooter from "@/components/footer/Footer.vue";
import CategoriesMobile from "@/components/categories-mobile/CategoriesMobile.vue";
import Network from "@/scripts/networks.js";

export default {
    components: {
        AppHeader,
        MenuBar,
        HomeContent,
        BottomFooter,
        CategoriesMobile,
    },
    data() {
        return {
            mobileCategoriesVisible: false,
            menuCategories: []
        };
    },
    methods: {
        async loadMenuCategories() {
            const jsonResponse = await Network.get(process.env.VUE_APP_API_BASE_URL + "categories/menu");
            this.menuCategories = jsonResponse["categories"];
        },
        onCategoriesClicked() {
            this.mobileCategoriesVisible = true;
        },
        onCategoriesCloseClicked() {
            this.mobileCategoriesVisible = false;
        },
        onCategorySelected() {
            this.mobileCategoriesVisible = false;
        },
    },
    beforeMount() {
        this.loadMenuCategories();
    },
};
</script>