import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SearchResultsView from '../views/SearchResultsView.vue'
import CreativeView from '../views/CreativeView.vue'
import StaticView from '../views/StaticView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/search',
        name: 'search',
        component: SearchResultsView
    },
    {
        path: '/creative',
        name: 'creative',
        component: CreativeView
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        props: { staticType: 'StaticDatenschutz' },
        component: StaticView
    },
    {
        path: '/nutzungsbedingungen',
        name: 'nutzungsbedingungen',
        props: { staticType: 'StaticNutzungsbedingungen' },
        component: StaticView
    },
    {
        path: '/impressum',
        name: 'impressum',
        props: { staticType: 'StaticImpressum' },
        component: StaticView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
