<template></template>

<script>
import Network from '@/scripts/networks.js'
import Session from '@/scripts/session.js'
export default {
    methods: {
        async handleCreativePage() {
            const type = this.$route.query["type"] // Traffic_source
            const ref = this.$route.query["ref"] // Publisher
            const offerId = this.$route.query["offerid"] // Offer id
            const shopId = this.$route.query["shopid"] // Shop id
            const log = this.$route.query["log"] // Tracking id
            const api = this.$route.query["api"] // Api

            const body = {
                offerId: offerId,
                shopId: shopId,
                sessionId: Session.getSession(),
                trafficSource: type,
                ref: ref,
                log: log,
                api: api,
            }
            let response = await Network.post(process.env.VUE_APP_API_BASE_URL + 'tracking/offer/creative', body)
            let url = response["clickOutUrl"]
            if (url != null) {
                window.location.replace(url)
            }
        }
    },
    watch: {
        '$route.query': {
            handler: function () {
                this.handleCreativePage()
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>