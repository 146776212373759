<template>
    <div class="menu-section">
        <div class="menu-section-container">
            <Categories class="categories" :categories="menuCategories" />
            <Hero />
        </div>
    </div>
</template>

<script>
import Categories from '@/components/categories/Categories.vue'
import Hero from '@/components/hero/Hero.vue'

export default {
    props: ['categories'],
    components: {
        Categories, Hero
    },
    data() {
        return {
            menuCategories: this.categories
        }
    },
    watch: {
        categories: {
            handler(newVal, _) {
                this.menuCategories = newVal
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>

<style>
/* Menu Section */

.menu-section {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-section-container {
    max-width: var(--container-width);
    min-height: 400px;
    padding-left: 1em;
    padding-right: 1em;
    display: grid;
    gap: 1em;
    flex: 1;
}

.hero-container {
    grid-column-start: 2;
    grid-column-end: 5;
}

/* Width >= 1920px */
@media (width >=120em) {
    .menu-bar-container {
        grid-template-columns: repeat(5, 2.4fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(5, 2.4fr);
    }

    .hero-container {
        grid-column-start: 2;
        grid-column-end: 6;
    }
}

/* Width >= 1536px and width < 1920px */
@media (width >=96em) and (width < 120em) {
    .menu-bar-container {
        grid-template-columns: repeat(5, 2.4fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(5, 2.4fr);
    }

    .hero-container {
        grid-column-start: 2;
        grid-column-end: 6;
    }
}

/* Width >= 1280px and width < 1536px */
@media (width >=80em) and (width < 96em) {
    .menu-bar-container {
        grid-template-columns: repeat(4, 3fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(4, 3fr);
    }

    .hero-container {
        grid-column-start: 2;
        grid-column-end: 5;
    }
}

/* Width >= 1140px and width < 1280px */
@media (width >=71.25em) and (width < 80em) {
    .menu-bar-container {
        grid-template-columns: repeat(4, 3fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(4, 3fr);
    }

    .hero-container {
        grid-column-start: 2;
        grid-column-end: 5;
    }
}

/* Width >= 1024px and width < 1140px */
@media (width >=64em) and (width < 71.25em) {
    .menu-bar-container {
        grid-template-columns: repeat(3, 4fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(3, 4fr);
    }

    .hero-container {
        grid-column-start: 2;
        grid-column-end: 4;
    }
}

/* Width >= 896px and width < 1024px */
@media (width >=56em) and (width < 64em) {
    .menu-bar-container {
        grid-template-columns: repeat(3, 4fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(3, 4fr);
    }

    .hero-container {
        grid-column-start: 2;
        grid-column-end: 4;
    }
}

/* Width >= 768px and width < 896px */
@media (width >=48em) and (width < 56em) {
    .categories {
        display: none;
    }

    .menu-bar-container {
        grid-template-columns: repeat(6, 2fr);
    }

    .menu-section-container {
        grid-template-columns: repeat(2, 6fr);
    }

    .hero-container {
        grid-column-start: 1;
        grid-column-end: 5;
    }
}

/* Width >= 568px and width < 768px */
@media (width >=35.5em) and (width < 48em) {
    .categories {
        display: none;
    }

    .menu-section-container {
        grid-template-columns: repeat(2, 6fr);
    }

    .menu-bar-container {
        grid-template-columns: repeat(6, 2fr);
    }

    .hero-container {
        grid-column-start: 1;
        grid-column-end: 5;
    }
}

/* Width < 568px */
@media (width < 35.5em) {
    .categories {
        display: none;
    }

    .hero-container {
        grid-column-start: 1;
        grid-column-end: 5;
    }
}
</style>