import Cookie from '@/scripts/cookie'
import Utils from '@/scripts/utils'

var session = {
    handleSessionId() {
        const currentSessionCookie = Cookie.getCookie(SessionIdName)
        if (currentSessionCookie === "") {
            console.log("There is no curent session.")
            const uuid = Utils.generateUUID()
            console.log("Set session:" + uuid)
            Cookie.setCookie(SessionIdName, uuid)
        } else {
            console.log("Current session already set: " + currentSessionCookie)
        }
    },
    getSession() {
        return Cookie.getCookie(SessionIdName)
    }
}

export default session

const SessionIdName = "PreistipSessionId"