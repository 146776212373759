<template>
    <template v-for="(offer, index) in offers" :key="offer.id">
        <OfferItem :offerItem="offer"/>
        <!--<AdsOfferItem v-if="(index + 1) % 18 === 0"/>-->
    </template>
</template>

<script>
import OfferItem from '@/components/offer/OfferItem.vue'
import AdsOfferItem from "@/components/offer/AdsOfferItem.vue";

export default {
    components: {
        OfferItem, AdsOfferItem
    },
    props: ['offers']
}
</script>