var network = {
    post(url, data) {
        return fireNetwork(url, HTTPMethodsType.POST, data)
    },
    get(url) {
        return fireNetwork(url, HTTPMethodsType.GET)
    },
}

export default network

const HTTPMethodsType = {
    POST: "POST",
    GET: "GET"
}

async function fireNetwork(url, type, data) {
    try {
        const response = await fetch(url, buildRequestSettings(type, data))
        const jsonResponse = await response.json();
        return jsonResponse
    } catch (e) {
        console.log(e)
    }
}

function buildRequestSettings(type, data) {
    if (data == null) {
        return {
            method: type
        };
    } else {
        return {
            method: type,
            body: JSON.stringify(data)
        };
    }
}