<template>
    <router-view />
</template>
  
<script>
import Session from '@/scripts/session.js'
import Params from '@/scripts/params.js'

export default {
    setup() {
        Session.handleSessionId()
        Params.checkAndSaveInitParams()
    }
}
</script>
  
  