<template>
    <HomeMenu :categories="menuCategories" />
    <div class="content">
        <HomeBestProducts />
    </div>
</template>

<script>
import HomeMenu from '@/components/home/HomeMenu.vue'
import HomeBestProducts from '@/components/home/HomeBestProducts.vue'

export default {
    props: ['categories'],
    components: {
        HomeMenu, HomeBestProducts
    },
    data() {
        return {
            menuCategories: this.categories
        }
    },
    watch: {
        categories: {
            handler(newVal, _) {
                this.menuCategories = newVal
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>

<style>
/* Content section */
.content {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    margin-bottom: 1em;
}

/* Width >= 1920px */
@media (width >=120em) {
    .best-products-container {
        grid-template-columns: repeat(5, 2.4fr);
    }
}

/* Width >= 1536px and width < 1920px */
@media (width >=96em) and (width < 120em) {
    .best-products-container {
        grid-template-columns: repeat(5, 2.4fr);
    }
}

/* Width >= 1280px and width < 1536px */
@media (width >=80em) and (width < 96em) {
    .best-products-container {
        grid-template-columns: repeat(4, 3fr);
    }
}

/* Width >= 1140px and width < 1280px */
@media (width >=71.25em) and (width < 80em) {
    .best-products-container {
        grid-template-columns: repeat(4, 3fr);
    }
}

/* Width >= 1024px and width < 1140px */
@media (width >=64em) and (width < 71.25em) {
    .best-products-container {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 896px and width < 1024px */
@media (width >=56em) and (width < 64em) {
    .best-products-container {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 768px and width < 896px */
@media (width >=48em) and (width < 56em) {
    .best-products-container {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 568px and width < 768px */
@media (width >=35.5em) and (width < 48em) {
    .best-products-container {
        grid-template-columns: 1fr 1fr;
    }
}

/* Width < 568px */
@media (width < 35.5em) {
    .best-products-container {
        grid-template-columns: 1fr;
    }
}
</style>