<template>
    <a href="" @click.prevent="onCategoryClciked(id, title)" @mouseenter="onCategoryMouseEvent(id, true)"
        @mouseleave="onCategoryMouseEvent(id, false)" class="category">
        <div class="category-title">{{ title }}</div>
        <div v-if="showChild" class="category-arrow"></div>
    </a>
</template>

<script>
export default {
    props: ['categoryItem', 'showChild'],
    data() {
        return {
            id: this.categoryItem["id"],
            title: this.categoryItem["title"]
        }
    },
    methods: {
        onCategoryClciked(id, title) {
            this.$parent.onCategoryClciked(id, title)
        },
        onCategoryMouseEvent(id, hover) {
            this.$parent.onCategoryMouseEvent(id, hover, this.showChild)
        },
    }
}
</script>

<style>
.category {
    min-height: 50px;
    line-height: 50px;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    align-items: center;
}

.category-title {
    font-weight: var(--fw-500);
    font-size: var(--fs-400);
    text-transform: capitalize;
    color: var(--primary-black)
}

.category:hover .category-title {
    color: var(--primary-color);
    transition: color 0.4s;
}

.category-arrow {
    width: 5px;
    height: 10px;
    background-image: url(../../assets/right-arrow.svg);
    margin-left: auto;
}
</style>