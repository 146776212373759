<template>
    <div class="menu-bar">
        <div class="menu-bar-container">
            <div class="categories-bar" @click="onCategoriesClicked">
                <div class="categories-icon"></div>
                <div class="categories-title">Alle Kategorien</div>
            </div>
            <div class="filters-bar" v-show="showFiltersBar" @click="onFiltersClicked">
                <img class="filters-icon" src="../../assets/filter-icon.svg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['showFiltersBar'],
    data() {
        return {
            shouldHandleCategoriesClicked: false
        }
    },
    methods: {
        onCategoriesClicked() {
            if (window.matchMedia("(width < 56em)").matches) {
                this.$parent.onCategoriesClicked()
            }
        },
        onFiltersClicked() {
            if (window.matchMedia("(width < 56em)").matches) {
                // this.$parent.onFiltersClicked()
            }
        }
    }
}
</script>

<style>
.categories-icon {
    width: 20px;
    height: 14px;
    background-image: url(../../assets/categories-open-icon.svg);
}

.categories-title {
    font-weight: var(--fw-600);
    font-size: var(--fs-400);
    text-transform: uppercase;
    color: var(--primary-grey)
}

.menu-bar {
    min-height: 50px;
    background-color: var(--secondary-black);
    display: flex;
    justify-content: center;
    flex: 1;
}

.menu-bar-container {
    max-width: var(--container-width);
    padding-left: 1em;
    padding-right: 1em;
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    gap: 1em;
    flex: 1;
}

.categories-bar {
    min-height: 50px;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.filters-bar {
    display: none;
    min-height: 50px;
    background-color: var(--primary-color);
    align-items: center;
    justify-content: center;
    align-self: end;
}

.filters-icon {
    width: 22px;
}

/* Width >= 768px and width < 896px */
@media (width >=48em) and (width < 56em) {
    .categories-title {
        display: none;
    }

    .categories-bar {
        cursor: pointer;
    }

    .filters-bar {
        display: flex;
        grid-column-start: 6;
        grid-column-end: 6;
    }
}

/* Width >= 568px and width < 768px */
@media (width >=35.5em) and (width < 48em) {
    .categories-title {
        display: none;
    }

    .categories-bar {
        cursor: pointer;
    }

    .filters-bar {
        display: flex;
        grid-column-start: 6;
        grid-column-end: 6;
    }
}

/* Width < 568px */
@media (width < 35.5em) {
    .categories-title {
        display: none;
    }

    .categories-bar {
        cursor: pointer;
    }

    .filters-bar {
        display: flex;
        grid-column-start: 4;
        grid-column-end: 4;
    }
}
</style>